<template>
  <div>
    <!-- 头部 -->
    <!-- <van-cell center is-link to="#" value="切换就诊卡"> -->
    <van-cell center class="van-cell_herder">
      <template slot="title">
        <span class="custom-title">{{ UserName }}</span>
      </template>
      <template slot="label">
        <span>{{ CardNo }}</span>
      </template>
      <template slot="default">
        <span @click="onqiehuan">切换用户</span>
      </template>
      <van-icon
        slot="right-icon"
        class="iconfont"
        @click="onqiehuan"
        class-prefix="icon"
        name="qiehuan"
        color="#57c4b7"
        style="margin-left: 2%"
      />
    </van-cell>
    <van-cell>
      <template slot="title">
        <!-- <span style="color:red;">*福贡县人民医院温馨提示：微信医院挂号可在微信医院上进行退号退费，其他渠道挂号的请到收费窗口进行处理</span> -->
        <!-- <span style="color:red;">*江城县人民医院温馨提示：微信医院挂号可在微信医院上进行退号退费，其他渠道挂号的请到收费窗口进行处理</span> -->
        <!-- <span style="color: red;font-size: 12px;"
          >*澜沧县第一人民医院温馨提示：微信医院挂号可在微信医院上进行退号退费，其他渠道挂号的请到收费窗口进行处理</span
        > -->
        <span style="color: red;font-size: 12px;"
          >*福贡县人民医院温馨提示：微信医院挂号可在微信医院上进行退号退费，其他渠道挂号的请到收费窗口进行处理</span
        >
      </template>
    </van-cell>

    <!-- 中间 -->
    <div class="regilist_center">
      <van-cell-group>
        <div style="width: 100%">
          <van-cell center>
            <template slot="icon">
              <van-dropdown-menu style="margin-left: 2%">
                <van-dropdown-item
                  v-model="value1"
                  :options="option1"
                  @change="medicalDrop"
                />
              </van-dropdown-menu>
            </template>
            <template slot="title">
              <div style="text-align: center">
                <span>{{ date }}</span>
                <!-- <van-calendar v-model="showTime" type="range" /> -->
              </div>
            </template>
            <template slot="right-icon">
              <div style="margin-right: 6%">
                <van-dropdown-menu active-color="#1989fa">
                  <van-dropdown-item
                    v-model="value2"
                    :options="option2"
                    @change="sortDrop"
                  />
                </van-dropdown-menu>
              </div>
            </template>
          </van-cell>
        </div>
      </van-cell-group>
    </div>

    <!-- 列表 -->
    <div>
      <van-cell-group v-for="(Ditem, index) in lists" :key="index">
        <van-list v-bind="Ditem">
          <div style="width: 100%">
            <van-cell
              center
              @click="
                onclickDetail(Ditem.isSee, Ditem.clinicNO)
              "
            >
              <template slot="icon">
                <img
                  class="GHJL_list_header_img"
                  src="@/assets/imagel/header.png"
                  alt=""
                />
              </template>
              <template slot="title">
                <span>{{ Ditem.doctName }}</span>
                <span style="font-size: 12px; color: #57c4b7"> | </span>
                <span style="font-size: 12px; color: #57c4b7">{{
                  Ditem.deptName
                }}</span>
                <span style="font-size: 12px; color: #57c4b7"> | </span>
                <span class="lable_cell_color_green">
                  {{ Ditem.stateDescript }}
                </span>
                <!-- <span v-if="Ditem.isSee" class="lable_cell_color_green"
                    >已就诊</span
                  >
                  <span v-else class="lable_cell_color_red">待就诊</span> -->
              </template>
              <template slot="label">
                <span style="font-size: 12px; color: #999999">
                  <!-- {{Ditem.regLevelName}} -->
                  {{ Ditem.regLevelName }}
                </span>
              </template>
              <template slot="right-icon">
                <div
                  v-if="Ditem.isSee == 1"
                  style="font-size: 16px; color: #57c4b7"
                >
                  已就诊
                  <!-- <div style="display: block; text-align: center">
                      <span style="font-size: 24px; color: #57c4b7">{{
                        Ditem.scheduleId
                      }}</span>
                      <span style="display: block; font-size: 12px"
                        >预约序号</span
                      >
                    </div> -->
                </div>
                <div v-else style="font-size: 16px; color: red">未就诊</div>
              </template>
            </van-cell>

            <van-cell>
              <template slot="title">
                <span v-if="Ditem.isSee">就诊时间:{{ Ditem.seeTime }}</span>
                <span v-else
                  >预约时间:{{ Ditem.regTime }}</span
                >
              </template>
              <template slot="right-icon">
                <van-button
                  :class="[
                    Ditem.isSee == 1
                      ? 'right_icon_cell'
                      : '',
                  ]"
                  size="small"
                  @click="onclickesc(Ditem.clinicNO)"
                  >取消挂号</van-button
                >
              </template>
            </van-cell>
          </div>
        </van-list>
      </van-cell-group>
    </div>

    <div
      style="
        font-size: 12px;
        color: #d3d3d3;
        margin: 1% 40%;
        width: auto;
        height: 40px;
      "
    >
      <span>&copy;拓实银医</span>
    </div>
  </div>
</template>
<script>
import ajax from "../../lib/ajax";
import store from "../../store";
import { Dialog, Toast } from "vant";
var nowDate = new Date();
// const OperatorId = "000000";
let listss = [];
export default {
  data() {
    return {
      UserName: "",
      CardNo: "",
      date: "",
      value1: 0,
      lists: [],
      isSeeList: {},
      currentDate: new Date(),
      onclickChangecard: false,
      value2: 0,
      iserweima: false,
      option1: [
        {
          text: "全部",
          value: 0,
        },
        {
          text: "已支付",
          value: 1,
        },
        {
          text: "已退款",
          value: 2,
        },
      ],
      option2: [
        {
          text: "默认排序",
          value: 0,
        },
        {
          text: "就诊排序",
          value: 1,
        },
        {
          text: "时间排序",
          value: 2,
        },
      ],
    };
  },
  created() {
    this.onload();
    // 初始化数据
    // this.getRegL();
    //初始化时间
    this.date = `${nowDate.getMonth() + 1}-${nowDate.getDate()}`;
    // const lastDate = new Date(new Date().setMonth(new Date().getMonth() + 1))
    // const date = [new Date(), lastDate];
    // this.onConfirm(date);
    //初始化lists[]
    //  this.medicalDrop(0);
    this.UserName = store.getters["store/userName"];
    this.CardNo = store.getters["store/userId"];
  },
  methods: {
    async onload() {
      // 异步更新数据
      setTimeout(() => {
        for (let i = 0; i < 0; i++) {
          this.lists.push(this.lists.length);
        }
        // 加载状态结束
        this.loading = false;

        // 数据全部加载完成
        if (this.lists.length == this.lists.length) {
          this.finished = true;
        }
      }, 500);
      var Date = {
        year: nowDate.getFullYear(),
        month:
          nowDate.getMonth() + 1 < 10
            ? "0" + (nowDate.getMonth() + 1)
            : "" + (nowDate.getMonth() + 1),
        date:
          nowDate.getDate() < 10
            ? "0" + nowDate.getDate()
            : "" + nowDate.getDate(),
      };
      var Today = Date.year + "-" + Date.month + "-" + Date.date;
      // alert(Today);
      let userId = store.getters["store/userId"];
      // let idcard = store.getters["store/idcard"];
      console.log(userId);
      await ajax
        .post("/Api/RegistrationService/QueryRegistRecords", {
          // userID: userId,
          userID:userId,
          startDate: Today + " 00:00:00",
          endDate: Today + " 23:59:59",
        })
        .then((res) => {
          listss = res.data.result.regRecordLists;
          console.log(listss);
          // let regs = [];
          // for (let i = 0; i < listss.length; i++) {
          //   listss[i].scheduleDate = listss[i].scheduleDate.substring(0, 10);
          //   regs.push(listss[i].regNo);
          //   if (listss[i].drId == "") {
          //     listss[i].regLevelName = "普通号";
          //     listss[i].drName = "门诊医生|医师";
          //   } else {
          //     listss[i].regLevelName = "专家号";
          //   }
          //   let name = listss[i].drName.split("|");
          //   listss[i].docname = name[0];
          // }
          this.lists = listss;
          // ajax
          //   .post("/Api/RegistrationService/IsSeeDoctor", {
          //     idCard: idcard,
          //     regNos: regs,
          //   })
          //   .then((res) => {
          //     console.log(res.data.result);
          //     this.isSeeList = res.data.result;
          //   })
          //   .catch((err) => {
          //     // console.log(err);
          //     Toast(err.message);
          //   });
          this.medicalDrop();
        });
    },
    //全部,已就诊,未就诊下拉菜单改变事假
    medicalDrop() {
      this.selectDropTime();
      this.sortDrop(this.value2);
    },
    //默认排序下拉菜单改变事件
    sortDrop(value) {
      if (value == 0) {
        // this.selectDropTime();
        this.lists = this.lists;
      } else if (value == 1) {
        this.lists.sort(this.sortDepNumber);
      } else if (value == 2) {
        this.lists.sort(this.sortTime);
      } else {
        this.$toast("出现未知错误!!!");
      }
    },
    //就诊排序
    sortDepNumber(obj1, obj2) {
      return obj1.scheduleId - obj2.scheduleId;
    },
    //时间排序
    sortTime(obj1, obj2) {
      return obj2.bookTime - obj1.bookTime;
    },
    //切换时间格式
    formatDate(date) {
      return `${date.getMonth() + 1}-${date.getDate()}`;
    },
    //下拉全部菜单筛选、时间筛选、排序
    selectDropTime() {
      this.lists = [];
      if (this.value1 == 0) {
        for (let i = 0; i < listss.length; i++) {
          let index = listss[i];
          this.lists.push(index);
        }
      } else if (this.value1 == 1) {
        for (let i = 0; i < listss.length; i++) {
          if (listss[i].state == 2) {
            let index = listss[i];
            this.lists.push(index);
          }
        }
      } else if (this.value1 == 2) {
        for (let i = 0; i < listss.length; i++) {
          if (listss[i].state == 3) {
            let index = listss[i];
            this.lists.push(index);
          }
        }
      } else {
        this.$toast("出现未知错误!!!");
      }
    },
    onclickesc(regNo) {
      // let openid = store.getters["wechat/openid"];
      // let name = store.getters["store/userName"];
      // let cardNo = store.getters["store/cardNo"];
      Dialog.confirm({
        title: "温馨提示",
        message: "确定取消挂号",
      })
        .then(async () => {
          console.log(regNo);
            await ajax
              .get(
                "/Api/RegistrationService/RegRefund?input=" +
                  regNo
              )
              .then(async (res) => {
                console.log(res.data.result);
                if (res.data.result === 1) {
                  Toast("您已成功退号，退款正在受理中，请注意查收");
                  this.onload();
                } else if (res.data.result === -1) {
                  Toast("订单不存在");
                } else if (res.data.result === -2) {
                  Toast("his返回失败");
                } else {
                  Toast("申请退款失败，请到窗口详询");
                }
              })
              .catch((err) => {
                console.log(err.message);
                Toast(err.message);
              });
        })
        .catch(() => {
          Toast("您取消了取消挂号!");
        });
    },
    onclickDetail: function (isSee, regNo) {
      // console.log(regStatus);
      if (isSee==1) {
        // alert(isSee);
        store.commit("store/setRegNo", regNo);
        this.$router
          .replace({
            path: "/RegIT",
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        // alert(regNo);
        store.commit("store/setRegNo", regNo);
        this.$router
          .replace({
            path: "/RegIF",
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    onqiehuan() {
      this.$router
        .replace({
          path: "/CardLs",
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //   async getRegL() {
    //   await ajax.post("/Api/RegistrationService/QueryRegistRecords",{ regNos: ["string"],outpatientId: "string" })
    //   .then(rep => {
    //     console.log(rep.data);
    //   }).catch((err)=>{
    //     console.log(err);
    //   });
    // },
  },
};
</script>

<style lang="less" scoped>
.regilist_center {
  .van-cell-group {
    // display: flex;
    .van-cell {
      margin: 0;
      padding: 0;
    }
  }
}
.right_icon_cell {
  display: none;
}
.lable_cell_color_green {
  // color: #57c4b7;
  font-size: 12px;
}
.lable_cell_color_red {
  color: red;
  font-size: 12px;
}
.GHJL_list_header_img {
  height: 70px;
  padding: 4px;
}
</style>
